import autoTable from "jspdf-autotable";
import http from "Utils/api2";
import { formattedDateServer } from "Utils/dateFunc";
import { SkPrice } from "Utils/dateFunc";

const { default: jsPDF } = require("jspdf");

// Function to fetch data from a specific page
const fetchData = async (url, page) => {
  try {
    const res = await http.get(`${url}&page=${page}`);
    return res.data?.data;
  } catch (error) {
    return { data: [] };
  }
  // try {
  //   const response = await fetch(`${url}&page=${page}`);
  //   const result = await response.json();
  //   return result.data; // Assuming the data is stored in result.data
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   return [];
  // }
};

export const generateUserPdfData = async ({ url, totalPages, loading }) => {
  // Get the total number of pages
  // const totalPages = await getTotalPages();

  // Accumulate data from all pages
  loading(true);
  try {
    // Array of promises for each page fetch
    const pagePromises = [];

    for (let page = 1; page <= totalPages; page++) {
      pagePromises.push(fetchData(url, page));
    }

    // Fetch all pages concurrently using Promise.all
    const isData = await Promise.all(pagePromises);

    // Combine the results from each page
    const combinedData = isData.flat(); // Or use .concat() if older versions of JS

    // console.log(combinedData, "ASdfs");
    // allData = combinedData;

    // Now generate the PDF with the accumulated data
    pdfGenerator(combinedData);
  } catch (error) {
    console.log(error, "pdf");
  } finally {
    loading(false);
  }
};

// Original pdfGenerator function
const pdfGenerator = (data) => {
  const doc = new jsPDF();

  const newData = data?.map((ele, i) => [
    i + 1,
    `${ele?.fullName} id- ${ele?._id}`,
    `${ele?.email}`,
    `${ele?.phoneNumber}`,
    ele?.createdAt
    ? `${formattedDateServer(new Date(ele?.createdAt) || "-") || "-"}`
    : "-",
  ]);

  autoTable(doc, {
    head: [["S.No", "Full Name", "Email", "Phone No.", "Registration Date"]],
    body: [...newData],
  });
  let fileName = "Customer Record List.pdf";

  doc.save(fileName);
};
