import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Pagination from "@mui/material/Pagination";
import MDBox from "components/MDBox";
import MenuSelecter from "components/MenuSelecter";
import { useDispatch, useSelector } from "react-redux";

import MDTypography from "components/MDTypography";
import {
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Delete,
  Input,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";

import Switch from "@mui/material/Switch";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { useMaterialUIController } from "context";
import DataTable from "examples/Tables/DataTable";
import { getAllProducts } from "redux/festures/productSlice";
import { useEffect, useState } from "react";
import SkLoading from "components/SkLoading";
import MDBadge from "components/MDBadge";
import { updateProducts } from "redux/festures/productSlice";
import { handleAlert } from "redux/festures/alertSlice";
import { getSingleProduct } from "redux/festures/productSlice";
import { getSingleReview } from "redux/festures/reviewSlice";
import { getAllGlobalProducts } from "redux/festures/productSlice";
import MDInput from "components/MDInput";
import { useLocation } from "react-router-dom";
import { getProductsWithoutLoading } from "redux/festures/productSlice";
import http from "Utils/api2";
import { getAllCity } from "redux/festures/citySlice";

export default function BasicTabs({
  setUpdateProduct,
  setCreateProduct,
  setGetUrl,
  setCreateLens,
  unShow,
  setViewData,
  setViewContactModal,
  setViewProductModal,
  setViewProductId,
  isPages,
  setIsPages,
  isSwitch,
  setIsSwitch,
  isOpen,
  setIsOpen,
  setUpdateProductModal,
  setProductId,
}) {
  const dispatch = useDispatch();
  const admin = localStorage.getItem("admin_id");
  const { pathname } = useLocation();

  const { AllProducts, Loading, page } = useSelector((state) => ({ ...state.isProducts }));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [value, setValue] = useState(0);
  const [isFilterName, setIsFilterName] = useState("name");
  const [isSort, setIsSort] = useState("Sort By");
  const [isSearch, setIsSearch] = useState("");

  //filter states

  const [filterCollapse, setFilterCollapse] = useState(false);
  const [AllSubCategory, setAllSubCategory] = useState([]);
  const [AllTax, setAllTax] = useState([]);
  const { city } = useSelector((state) => state.isCity);

  const [rowsData, setRowsData] = useState([]);

  const [current, setcurrent] = useState(null);

  //Static Filters

  const dateFilter = [
    {
      _id: 0,
      name: "All Products",
    },
    {
      _id: "today",
      name: "Today",
    },
    {
      _id: "week",
      name: "This Week",
    },
    {
      _id: "month",
      name: "This Month",
    },
    {
      _id: "year",
      name: "This Year",
    },
    // {
    //   _id: 'manually',
    //   name: 'Manually'
    // },
  ];

  const sortByPrice = [
    {
      _id: 0,
      name: "All",
    },
    {
      _id: "high_to_low",
      name: "High To Low",
    },
    {
      _id: "low_to_high",
      name: "Low To High",
    },
  ];

  const isDisableFilter = [
    {
      _id: false,
      name: "Active",
    },
    {
      _id: true,
      name: "Deleted",
    },
    {
      _id: "",
      name: "All",
    },
  ];

  const columns = {
    service: {
      name: "Service Products",
      value: "service",
      pagination: true,
      col: [
        { Header: "S.No", accessor: "no" },
        { Header: "Thumbnail", accessor: "thumbnail" },
        { Header: "Title", accessor: "title" },
        { Header: "Price", accessor: "price" },
        { Header: "MRP", accessor: "mrp" },

        { Header: "Category", accessor: "category" },

        { Header: "delete", accessor: "delete" },

        { Header: "View", accessor: "view" },
        { Header: "Action", accessor: "action" },
      ],
      // filter: {
      //   filter: 0,
      //   categoryId: 0,
      //   cityId: 0,
      //   price: 0,
      //   taxId: 0,
      //   disable: false,
      //   productId: 0,
      //   search: "",
      // },
    },
    ecomm: {
      name: "Ecommerce Products",
      value: "ecomm",
      pagination: true,
      col: [
        { Header: "S.No", accessor: "no" },
        { Header: "Thumbnail", accessor: "thumbnail" },
        { Header: "Title", accessor: "title" },
        { Header: "Price / MRP", accessor: "price/mrp" },

        { Header: "Category", accessor: "category" },
        { Header: "Stock", accessor: "stock" },

        { Header: "delete", accessor: "delete" },
        { Header: "View", accessor: "view" },
        { Header: "Action", accessor: "action" },
      ],
      // filter: {
      //   filter: 0,
      //   categoryId: 0,
      //   price: 0,
      //   taxId: 0,
      //   disable: false,
      //   productId: 0,
      //   page: 0,
      //   search: "",
      // },
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (pathname === "/products/ecom-products") setcurrent(columns?.ecomm);
    else setcurrent(columns?.service);
  }, [pathname]);

  useEffect(() => {
    if (current?.value === "service") {
      dispatch(getAllCity(`/getAllCityByAdmin/${admin}`));
    }

    if (current?.value === "service" || current?.value === "ecomm")
      http
        .get(
          current?.value !== "service"
            ? `/eCommerce/getAllSubCategory/${admin}`
            : `/getAllSubCategory/${admin}`
        )
        .then((response) => {
          setAllSubCategory(response?.data?.data);
        })
        .catch((error) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: "error",
              msg: error?.response?.data?.message,
            })
          );
        });

    http
      .get(`/getAllTax/${admin}`)
      .then((response) => {
        const tax =
          response?.data?.data && response?.data?.data?.length
            ? [...response?.data?.data]?.map((ele) => {
                const temp = { ...ele };
                temp.taxPercent = `${temp.taxPercent} %`;
                return temp;
              })
            : [];
        setAllTax(tax);
      })
      .catch((error) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: "error",
            msg: error?.response?.data?.message,
          })
        );
      });
  }, [current?.value]);

 

  const [categoryId, setCategoryId] = useState(0);
  const [filter, setFilter] = useState(0);
  const [price, setprice] = useState(0);
  const [taxId, setTaxId] = useState(0);
  const [disable, setDisable] = useState(false);
  const [search, setSearch] = useState("");


  useEffect(() => {
    if (current?.value === "service") {
      dispatch(
        getAllGlobalProducts(
          `/filterProductByDate/${admin}?${categoryId ? `categoryId=${categoryId}` : ""}${
            filter ? `&filter=${filter}` : ""
          }${price ? `&price=${price}` : ""}${taxId ? `&taxId=${taxId}` : ""}${`&disable=${
            disable === 0 ? "" : disable === false ? false : true || ""
          }`}${search ? `&search=${search}` : ""}${isPages ? `&page=${isPages}` : ""}`
        )
      );
    } else if (current?.value === "ecomm") {
      dispatch(
        getAllGlobalProducts(
          `/eCommerce/filterProductByDate/${admin}?${categoryId ? `categoryId=${categoryId}` : ""}${
            filter ? `&filter=${filter}` : ""
          }${price ? `&price=${price}` : ""}${taxId ? `&taxId=${taxId}` : ""}${`&disable=${
            disable === 0 ? "" : disable === false ? false : true || ""
          }`}${search ? `&search=${search}` : ""}${isPages ? `&page=${isPages}` : ""}`
        )
      );
    }
  }, [isPages, categoryId, filter, price, taxId, disable, search, isOpen, current,current?.value]);

  useEffect(() => {
    if (current?.value === "service") {
      setRowsData(
        AllProducts && AllProducts?.length
          ? AllProducts?.map((value, index) => ({
              no: (
                <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                  {index + 1}
                </MDTypography>
              ),
              thumbnail: (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 0.5,
                  }}
                >
                  <MDBox sx={{ height: 40, width: 40 }}>
                    <img
                      src={`${process.env.REACT_APP_URI}/${value?.thumnail}`}
                      alt={"img"}
                      onError={(e) => {
                        (e.onError = null),
                          (e.target.src = require("../../../assets/images/bg-profile.png"));
                      }}
                      style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                    />
                  </MDBox>
                </MDBox>
              ),
              title: (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 0.5,
                    flexDirection: "column",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                >
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                    style={{
                      maxWidth: "250px",
                      lineHeight: "20px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Name: {value?.title || "-"}
                  </MDTypography>

                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                    fontSize={14}
                  >
                    ProductId: {value?._id || "-"}
                  </MDTypography>
                </MDBox>
              ),
              price: (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                >
                  {value?.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  }) || "-"}
                </MDTypography>
              ),
              mrp: (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                >
                  {value?.mrp.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  }) || "-"}
                </MDTypography>
              ),
              category: (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  lineHeight={1}
                  style={{
                    maxWidth: "250px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {value?.categoryId?.name || "-"}
                </MDTypography>
              ),
              view: (
                <IconButton
                  aria-label="action_edit"
                  onClick={() => {
                    setViewProductModal(true);
                    setViewProductId(value);
                  }}
                >
                  <Visibility
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                </IconButton>
              ),

              delete: (
                <Tooltip title={value?.disable ? "move to Active" : "delete"}>
                  <IconButton
                    aria-label="action_edit"
                    // disabled={value?.disable}
                    onClick={() => {
                      dispatch(
                        updateProducts({
                          url: `${process.env.REACT_APP_APII}/disableProduct/${value?._id}/${admin}`,
                        })
                      ).then((data) => {
                        // console.log(data,"ghvhvhvhbvh")
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: `${data?.payload?.success ? "success" : "error"}`,
                            msg: data?.payload?.message,
                          })
                        );
                        if (data?.payload?.success) {
                          dispatch(
                            getAllGlobalProducts(
                              `${process.env.REACT_APP_APII}/filterProductByDate/${admin}?disable=${
                                disable === 0 ? "" : disable === false ? false : true || ""
                              }`
                            )
                          );
                        }
                      });
                    }}
                  >
                    {value?.disable ? (
                      <Input
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? info.main : dark.main,
                        })}
                      />
                    ) : (
                      <Delete
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? info.main : dark.main,
                        })}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              ),
              action: (
                <IconButton
                  aria-label="action_edit"
                  onClick={() => {
                    setIsOpen(true);
                    setUpdateProductModal(true);
                    // console.log(value?._id);
                    dispatch(getSingleProduct(value));
                  }}
                >
                  <Edit
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                </IconButton>
              ),
            }))
          : null
      );
    } else if (current?.value === "ecomm") {
      const temprows =
        AllProducts &&
        AllProducts?.length &&
        AllProducts?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          thumbnail: (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
              }}
            >
              <MDBox sx={{ height: 40, width: 40 }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.thumnail}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null),
                      (e.target.src = require("../../../assets/images/bg-profile.png"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox>
            </MDBox>
          ),

          title: (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
                flexDirection: "column",
                // maxWidth: '10rem',
                overflow: "hidden",
                // textOverflow: "ellipsis",
              }}
            >
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                style={{
                  maxWidth: "250px",
                  lineHeight: "20px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Name: {value?.title || "-"}
              </MDTypography>

              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                fontSize={14}
              >
                ProductId: {value?._id || "-"}
              </MDTypography>
            </MDBox>
          ),
          "price/mrp": (
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 0.5,
                flexDirection: "column",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
              }}
            >
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
              >
                {value?.price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "INR",
                }) || "-"}
              </MDTypography>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
                fontSize={10}
              >
                /
                {value?.mrp.toLocaleString("en-US", {
                  style: "currency",
                  currency: "INR",
                }) || "-"}
              </MDTypography>
            </MDBox>
          ),
          time: (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
            >
              {value?.time || "-"} Minutes
            </MDTypography>
          ),
          category: (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
              style={{
                maxWidth: "250px",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value?.categoryId?.name || "-"}
            </MDTypography>
          ),
          stock: (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
            >
              {value?.stock || "0"}
            </MDTypography>
          ),
          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setViewProductModal(true);
                setViewProductId(value);
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),

          delete: (
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                // disabled={value?.disable}
                onClick={() => {
                  dispatch(
                    updateProducts({
                      url: `${process.env.REACT_APP_APII}/eCommerce/disableProduct/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    // console.log(data,"ghvhvhvhbvh")
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(
                        getAllGlobalProducts(
                          `${
                            process.env.REACT_APP_APII
                          }/eCommerce/filterProductByDate/${admin}?disable=${
                            disable === 0 ? "" : disable === false ? false : true || ""
                          }`
                        )
                      );
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),
          action: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpen(true);
                setUpdateProductModal(true);
                // console.log(value?._id);
                dispatch(getSingleProduct(value));
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
        }));
      setRowsData(temprows);
    }
  }, [AllProducts]);

  return (
    <MDBox sx={{ width: "100%" }}>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 3,
          width: "100%",
          flexDirection: "row",
          p: "2%",
        }}
      >
        <MDBox
          // px={3}
          sx={({ palette: { dark, white, info }, breakpoints }) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "70%",
            gap: "2%",
            [breakpoints.up("xs")]: {
              flexDirection: "column",
            },
            [breakpoints.up("sm")]: {
              flexDirection: "column",
            },
            [breakpoints.up("md")]: {
              flexDirection: "column",
            },
            [breakpoints.up("lg")]: {
              flexDirection: "column",
            },
          })}
        >
          <MDBox
            sx={({ palette: { dark, white, info }, breakpoints }) => ({
              [breakpoints.up("xs")]: {
                flexDirection: "column",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 1.5,
                pl: 3,
              },
              [breakpoints.up("sm")]: {
                flexDirection: "column",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 1.5,
                pl: 3,
              },
              [breakpoints.up("md")]: {
                flexDirection: "column",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 1.5,
                pt: 4,
              },
              [breakpoints.up("lg")]: {
                flexDirection: "column",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 1.5,
                pt: 4,
              },
            })}
          >
            <MDTypography variant="button">
              {" "}
              {current?.value === "ecomm" ? "Product Filter" : "Service Filter"}
            </MDTypography>

            <MDInput
              disabled={Loading}
              placeholder="Name, Include, Exclude, Id..."
              type="text"
              fullWidth
              name="sarch here"
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={({ palette: { dark, white, info } }) => ({
                      backgroundColor: darkMode ? white.main : dark.main,
                      height: "100%",
                      padding: "1rem",
                      borderRadius: "5px",
                    })}
                  >
                    {filterCollapse ? (
                      <KeyboardArrowUpRounded
                        onClick={() => setFilterCollapse(false)}
                        size="20"
                        sx={({ palette: { dark, white, info } }) => ({
                          color: !darkMode ? white.main : dark.main,
                          cursor: "pointer",
                        })}
                      />
                    ) : (
                      <KeyboardArrowDownRounded
                        onClick={() => setFilterCollapse(true)}
                        size="20"
                        sx={({ palette: { dark, white, info } }) => ({
                          color: !darkMode ? white.main : dark.main,
                          cursor: "pointer",
                        })}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                if (current?.value === "service" || current?.value === "ecomm") {
                  setIsPages(1);
                  setSearch(e.target.value);
                }
              }}
            />
          </MDBox>

          <Collapse in={filterCollapse} timeout="auto" unmountOnExit sx={{ width: "100%", p: 3 }}>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "2%",
                width: "100%",
                [breakpoints.up("xs")]: {
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                },
                [breakpoints.up("sm")]: {
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                },
                [breakpoints.up("md")]: {
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
                [breakpoints.up("lg")]: {
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              })}
            >
              <MDBox width="23%" display="flex" flexDirection="column">
                <MDTypography variant="button">Filter By Duration</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filter}
                  onChange={(e) => {
                    setIsPages(1);
                    setFilter(e.target.value);
                  }}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {dateFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
              <MDBox width="23%" display="flex" flexDirection="column">
                <MDTypography variant="button">Filter By Category</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={categoryId}
                  onChange={(e) => {
                    setIsPages(1);
                    setCategoryId(e.target.value);
                  }}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  <MenuItem value={0}>Choose Category</MenuItem>
                  {AllSubCategory?.map((ele, i) => (
                    <MenuItem key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>

              <MDBox width="23%" display="flex" flexDirection="column">
                <MDTypography variant="button">Sort By Price</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={price}
                  onChange={(e) => {
                    setIsPages(1);
                    setprice(e.target.value);
                  }}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {sortByPrice?.map((ele, i) => (
                    <MenuItem key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
              <MDBox width="23%" display="flex" flexDirection="column">
                <MDTypography variant="button">Filter By Tax</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={taxId}
                  onChange={(e) => {
                    setIsPages(1);
                    setTaxId(e.target.value);
                  }}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  <MenuItem value={0}>Choose Tax Slab</MenuItem>
                  {AllTax?.map((ele, i) => (
                    <MenuItem key={i} value={ele?._id}>
                      {ele?.taxPercent}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>
          </Collapse>
        </MDBox>
        <MDBox width="23%" display="flex" flexDirection="column" px={3} pt={3}>
          <MenuSelecter isDisable={disable} setIsDisable={setDisable} />
        </MDBox>
      </MDBox>

      {Loading ? (
        <SkLoading />
      ) : (
        // tabsName.map((elm, i) => {
        //   return (
        //     <>
        //       <TabPanel value={value} index={i}>
        //         {/* <ProductsTable
        //         columns={current.col}
        //         rows={rows}
        //         current={current}
        //         currentFilter={currentFilter}
        //         setCurrentFilter={setCurrentFilter}
        //         filters={filters}
        //         filterInput={filterInput}
        //         setFilterInput={setFilterInput}
        //       /> */}
        //         {AllProducts && AllProducts.length > 0 ? (
        //           <DataTable
        //             table={{
        //               columns: current.col,
        //               rows: rowsData,
        //             }}
        //             isSorted={false}
        //             entriesPerPage={false}
        //             isPages={AllProducts && AllProducts.length}
        //             noEndBorder
        //             canSearch={false}
        //             showTotalEntries={false}
        //             pagination={false}
        //             isPagination={false}
        //           />
        //         ) : (
        //           <MDBox
        //             // key={index}
        //             display="flex"
        //             justifyContent="center"
        //             gap={2}
        //             alignItems="center"
        //           // width={"100%"}
        //           >
        //             <MDTypography variant="h6">Something went wrong !</MDTypography>
        //           </MDBox>
        //         )}
        //         {current.pagination ? (
        //           <MDBox className="center" py={3}>
        //             {/* <Pagination
        //             count={totalPage}
        //             page={filterPage}
        //             onChange={handlePageChange}
        //             variant="outlined"
        //             color="primary"
        //           /> */}
        //           </MDBox>
        //         ) : null}
        //       </TabPanel>
        //     </>
        //   );
        // })
        <>
          {current && AllProducts && AllProducts.length > 0 ? (
            <DataTable
              table={{
                columns: current?.col,
                rows: rowsData || [],
              }}
              isSorted={false}
              entriesPerPage={false}
              isPages={AllProducts && AllProducts.length}
              noEndBorder
              canSearch={false}
              showTotalEntries={false}
              pagination={false}
              isPagination={false}
            />
          ) : (
            <MDBox
              // key={index}
              display="flex"
              justifyContent="center"
              gap={2}
              alignItems="center"
              // width={"100%"}
            >
              <MDTypography variant="h5"> Product Not Found ...!</MDTypography>
            </MDBox>
          )}
          {current?.pagination ? (
            <MDBox className="center" py={3}>
              {/* <Pagination
                    count={totalPage}
                    page={filterPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                  /> */}
            </MDBox>
          ) : null}
        </>
      )}
    </MDBox>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

BasicTabs.propTypes = {
  //   children: PropTypes.node,
  setUpdateProduct: PropTypes.any,
  setCreateProduct: PropTypes.any,
  setGetUrl: PropTypes.any,
  setCreateLens: PropTypes.any,
  unShow: PropTypes.any,
  setViewData: PropTypes.any,
  setViewContactModal: PropTypes.any,
  setViewProductModal: PropTypes.any,
  setIsSwitch: PropTypes.any,
  isOpen: PropTypes.any,
  setIsOpen: PropTypes.any,
  setProductId: PropTypes.any,
  setUpdateProductModal: PropTypes.any,
  isSwitch: PropTypes.any,
  setIsPages: PropTypes.any,
  setViewProductId: PropTypes.string,
  isPages: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
