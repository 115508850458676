import moment from "moment";

export const formattedDateServer = function (d = new Date()) {
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return `${day}-${month}-${year}`;
};
export const camelToFlat = (camel) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  let flat = "";

  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};

export const SkDate = (SkDate) => {
  return SkDate?.toLocaleDateString("en-GB", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const SkPrice = (SkPrice) => {
  return SkPrice?.toLocaleString("en-US", {
    style: "currency",
    currency: "INR",
  });
};

export function formatDate(timestamp) {
  const currentDate = new Date();
  const targetDate = new Date(timestamp);

  const isYesterday =
    targetDate.getDate() === currentDate.getDate() - 1 &&
    targetDate.getMonth() === currentDate.getMonth() &&
    targetDate.getFullYear() === currentDate.getFullYear();

  const time = targetDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  if (isYesterday) {
    return `Yesterday at ${time}`;
  } else if (targetDate.getDate() === currentDate.getDate()) {
    return `Today at ${time}`;
  } else {
    return `${formattedDateServer(new Date(timestamp))} at ${time}`;
  }
}
export function formatTimeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) {
    return "Just now";
  }

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  return `${formattedHours}:${minutes} ${ampm}`;
}

export function debounceFunction(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
// utils.js
export function formatDateString(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  
  const isToday = date.toDateString() === now.toDateString();
  
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    return 'Today';
  } else if (isYesterday) {
    return 'Yesterday';
  } else {
    return date.toLocaleDateString(); // Or any other format you prefer
  }
}

export function groupByDate(items) {
  return items.reduce((groups, item) => {
    const dateKey = new Date(item.createdAt).toDateString();
    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(item);
    return groups;
  }, {});
}
