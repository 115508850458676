import { Box } from '@mui/material'
import SkLoading from 'components/SkLoading'
import React from 'react'

const index = () => {
  return (
    <Box sx={{width:"100%",height: "50rem",display:"flex",justifyContent:"center", alignItems:"center"
    }} >
      <SkLoading/>
    </Box>
  )
}

export default index
