import { Card, Divider } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import React from "react";
import { useSelector } from "react-redux";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
import PropTypes from "prop-types";
import { sanitize } from "dompurify";

const View = ({ isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  //   const { Loading, isOpenUpdate } = useSelector((data) => ({ ...data.isBlogs }));

  // console.log(isOpenUpdate, "asdgagg");
  return (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            Contact us Details
          </MDTypography>
        </Card>

        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 2,
            width: "100%",
            //   height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          })}
        >
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              // border: 0.5,
              // borderColor: darkMode ? white.main : dark.main,
              // borderRadius: 3,

              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1.5,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6"> Name :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {isOpenUpdate?.fullName}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Email :</MDTypography>
              <MDTypography variant="h6">{isOpenUpdate?.email} </MDTypography>
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Mobile :</MDTypography>
              <MDTypography variant="h6">{isOpenUpdate?.mobile || "-"} </MDTypography>
            </MDBox>
            {/* <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">description :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {isOpenUpdate?.discription} %{" "}
              </MDTypography>
            </MDBox> */}

            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Contact created date :</MDTypography>
              <MDTypography variant="h6">
                {formattedDateServer(new Date(isOpenUpdate?.createdAt)) || "N/A"}{" "}
              </MDTypography>
            </MDBox>

            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                borderTop: 1,
                borderTopColor: darkMode ? white.main : info.main,
                mt: 3,
              })}
            >
              {" "}
              <MDBadge
                badgeContent="Description"
                color="success"
                variant="gradient"
                size="lg"
                sx={{ zIndex: 10, mt: -1.5 }}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              gap={2}
              alignItems="flex-start"
              width={"100%"}
            >
              <MDTypography
                variant="h6"
                sx={{
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": 2,
                  "-webkit-box-orient": "vertical",
                  lineHeight: "30px",
                  maxWidth: "100%",
                  justifyContent: "flex-start",
                  display: "flex",
                }}
              >
                {isOpenUpdate?.discription}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default View;
View.propTypes = {
  isOpenUpdate: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
  setIsOpen: PropTypes.any,
};
