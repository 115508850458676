import { Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import http from "Utils/api";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatComponent from "./Options/ChatComponent";
import socket from "services/socket";
import { useLocation } from "react-router-dom";

const SupportChat = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state } = useLocation();
  const isData = state?.userId;

  return (
    <>
      {" "}
      <DashboardLayout>
        <DashboardNavbar />
        {/* <MDBox mb={2} /> */}
        <MDBox py={3}>
          <Card overflowY="hidden">
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Support Chat
              </MDTypography>
            </MDBox>
            <MDBox
              mx={2}
              my={2.5}
              py={1}
              px={2}
              // variant="gradient"
              // bgColor="dark"
              borderRadius="lg"
              coloredShadow="info"
              sx={
                {
                  // height: "80vh",
                }
              }
            >
              <ChatComponent data={isData} />
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default SupportChat;
