import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const imageDownload = createAsyncThunk("imageDownload", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const imageDownloader = createSlice({
  name: "imageDownloader",
  initialState: {
    Loading: false,
    image: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(imageDownload.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(imageDownload.fulfilled, (state, action) => {
        state.Loading = false;
        state.image = action.payload;
      })
      .addCase(imageDownload.rejected, (state, action) => {
        state.Loading = false;
      });
  },
});
export default imageDownloader.reducer;
