import * as XLSX from "xlsx";
import http from "Utils/api2";
import { formattedDateServer } from "Utils/dateFunc";
import { SkPrice } from "Utils/dateFunc";

// Function to fetch data from a specific page
const fetchData = async (url, page) => {
  try {
    const res = await http.get(`${url}&page=${page}`);
    return res.data?.data;
  } catch (error) {
    return { data: [] };
  }
  // try {
  //   const response = await fetch(`${url}&page=${page}`);
  //   const result = await response.json();
  //   return result.data; // Assuming the data is stored in result.data
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   return [];
  // }
};

export const generatePartnerExcelData = async ({ url, totalPages, loading }) => {
  // Get the total number of pages
  // const totalPages = await getTotalPages();

  // Accumulate data from all pages
  loading(true);
  try {
    // Array of promises for each page fetch
    const pagePromises = [];

    for (let page = 1; page <= totalPages; page++) {
      pagePromises.push(fetchData(url, page));
    }

    // Fetch all pages concurrently using Promise.all
    const isData = await Promise.all(pagePromises);

    // Combine the results from each page
    const combinedData = isData.flat(); // Or use .concat() if older versions of JS

    // console.log(combinedData, "ASdfs");
    // allData = combinedData;

    // Now generate the PDF with the accumulated data
    exportToExcel(combinedData);
  } catch (error) {
    console.log(error, "pdf");
  } finally {
    loading(false);
  }
};

const exportToExcel = (allListData) => {
  const data = allListData?.map((ele, i) => ({
    "S.No": i + 1,
    Name: `${ele?.name || "-"}`,
    PartnerId: `${ele?._id || "-"}`,
    Email: `${ele?.email || "-"}`,
    "Phone No.": `${ele?.phoneNumber || "-"}`,
    "Partner Create Date": ele?.createdAt
    ? `${formattedDateServer(new Date(ele?.createdAt) || "-") || "-"}`
    : "-",
    Address: `${ele?.address || "-"}`,
    Pincode: `${ele?.pincode || "-"}`,

    Remark: `${ele?.remark || "-"}`,
    "selfie image": `${process.env.REACT_APP_URI}/${ele?.selfie?.image || "-"}`,
    "selfie status": `${ele?.selfie?.status || "-"}`,
    "Document-Type": `${ele?.idDocument?.type || "-"}`,
    "Document-status": `${ele?.idDocument?.status || "-"}`,
    "Document number": `${ele?.idDocument?.addharNumber || "-"}`,
    "Document frontImage": `${process.env.REACT_APP_URI}/${ele?.idDocument?.frontImage || "-"}`,
    "Document backImage": `${process.env.REACT_APP_URI}/${ele?.idDocument?.backImage || "-"}`,
    "profile Verificetion Completed": `${
      ele?.idDocument?.profileVerificetionCompleted ? "Yes" : "No" || "-"
    }`,
  }));
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Partner");

  // Set column widths
  worksheet["!cols"] = [
    { wpx: 50 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    // { wpx: 150 },
    // { wpx: 150 },
  ];

  // Get the range of the worksheet to ensure all cells exist
  const range = XLSX.utils.decode_range(worksheet["!ref"]);

  if (range.s.r === 0) {
    const headerStyle = {
      fill: { fgColor: { rgb: "FFFFA500" } }, // Orange background color
      font: { color: { rgb: "FFFFFFFF" }, bold: true, sz: 12 }, // White font color, bold, and size 12
    };
    worksheet["A1"].s = headerStyle;
    worksheet["B1"].s = headerStyle;
    worksheet["C1"].s = headerStyle;
    worksheet["D1"].s = headerStyle;
    worksheet["E1"].s = headerStyle;
    worksheet["F1"].s = headerStyle;
    worksheet["G1"].s = headerStyle;
    worksheet["H1"].s = headerStyle;
    worksheet["I1"].s = headerStyle;
    worksheet["J1"].s = headerStyle;
    worksheet["K1"].s = headerStyle;
    worksheet["L1"].s = headerStyle;
    worksheet["M1"].s = headerStyle;
    worksheet["N1"].s = headerStyle;
    worksheet["O1"].s = headerStyle;
    worksheet["P1"].s = headerStyle;
    worksheet["Q1"].s = headerStyle;
    // worksheet["R1"].s = headerStyle;
    // worksheet["S1"].s = headerStyle;
  }

  // Save to file
  XLSX.writeFile(workbook, "Partner List.xlsx");
};
