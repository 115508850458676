import { Card, CircularProgress } from "@mui/material";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import AstrieskIcon from "components/AstrieskIcon";
import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import { getGlobalCategory } from "redux/festures/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { creatCategoryData } from "redux/festures/categorySlice";
import { handleAlert } from "redux/festures/alertSlice";
import { updateCategory } from "redux/festures/categorySlice";
import { Cancel } from "@mui/icons-material";
import { useMaterialUIController } from "context";
import { unlinkImage } from "redux/festures/categorySlice";

const PcatogeroyUpdate = ({ viewData, isPUpdates,setIsOpenUpdate,filter }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();

  const admin = localStorage.getItem("admin_id");

  const [profile, setProfile] = useState({
    categoryName: "",
    uploadPic: "",
    bannerImage: "",
    bannerVideo: "",
    cityId: [],
    categoryId: "",
    category__Id: "",
    isShow: "",
    serverBannerImage: null,
  });

  const {
    Loading,
    category,
    subCategory,
    IsLoading,
    isPages,
    subCategoryData,
    createUpdateLoading,
  } = useSelector((state) => ({
    ...state.isCategory,
  }));

  useEffect(() => {
    if (viewData && isPUpdates) {
      setProfile({
        categoryName: viewData?.name || "",
        uploadPic: "",
        bannerImage: "",
        bannerVideo: "",
        // cityId: viewData?.cityId?.length ? viewData?.cityId?.map((ele) => ele?._id) : [],
        categoryId: viewData?._id || "",
        category__Id: "",
        isShow: viewData?.icon || "",
        serverBannerImage: viewData?.banner?.length
          ? viewData?.banner?.filter((ele) => ele?.type === "IMAGE")
          : null,
      });
    } else {
      setProfile({
        categoryName: "",
        uploadPic: "",
        bannerImage: "",
        bannerVideo: "",
        cityId: [],
        categoryId: "",
        category__Id: "",
        isShow: "",
        serverBannerImage: null,
      });
    }
  }, [viewData, isPUpdates]);

  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", profile?.categoryName);
    profile?.uploadPic && formData.append("icon", profile.uploadPic);
    if (profile?.bannerImage && profile?.bannerImage?.length)
      profile?.bannerImage?.map((e) => formData.append("banner", e));
    if (profile?.bannerVideo) formData.append("banner", profile?.bannerVideo);

    dispatch(
      isPUpdates
        ? updateCategory({
            url: `/updateCategory/${profile?.categoryId}/${admin}`,
            data: formData,
          })
        : creatCategoryData({
            url: `/createCategory/${admin}`,
            reqBody: formData,
          })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        setProfile({
          categoryName: "",
          uploadPic: "",
          bannerImage: "",
          bannerVideo: "",
          cityId: [],
          categoryId: "",
          category__Id: "",
          isShow: "",
          serverBannerImage: null,
        });

        dispatch(getGlobalCategory(`/getAllCategoryWithPcategory/${admin}?disable=${
          filter === false ? false : filter || ""
        }`));
    
        setIsOpenUpdate(false);
      
      }
    });

  };
  return (
    
    <MDBox
    display="flex"
    alignItems="center"
    lineHeight={1}
    sx={({ palette: { dark, white, info } }) => ({
      border: 0.5,
      borderColor: darkMode ? white.main : dark.main,
      borderRadius: 3,
      p: 2,
      width: "100%",
      //   height: "70vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: 2,
    })}
  >
    <Card
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 , 10px",
      }}
    >
      <MDTypography variant="h6" py={0.9}>
      {isPUpdates ? " Update Category " : "Create Category"}
      </MDTypography>
    </Card>


    <MDBox
      display="flex"
      alignItems="center"
      lineHeight={1}
      sx={{
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
      component="form"
      role="form"
      onSubmit={handleSubmitCategory}
    >
  
      <MDBox
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        lineHeight={1}
        gap={3}
        width={"75%"}
      >
        <MDTypography variant="h6">
          Category Name <AstrieskIcon />
        </MDTypography>
        <MDInput
          disabled={createUpdateLoading}
          required={true}
          placeholder="category name"
          type="text"
          fullWidth
          pl={3}
          value={profile?.categoryName}
          onChange={(e) => {
            setProfile((prev) => ({
              ...prev,
              categoryName: e.target.value,
            }));
          }}
        />
      </MDBox>

      <MDBox
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        lineHeight={1}
        gap={3}
        width={"75%"}
      >
        <MDTypography variant="h6">
          Category Image <AstrieskIcon />
          <MDTypography variant="body1" component="span" fontSize={11}>
            &nbsp; ( image size - 178 × 178 px )
          </MDTypography>
        </MDTypography>
        <ImagePicker
          disabled={createUpdateLoading}
          // required={true}
          name="Image"
          multiple={false}
          images={profile?.uploadPic}
          setImages={(e) => {
            setProfile((prev) => ({
              ...prev,
              uploadPic: e,
            }));
          }}
        />

        {profile?.uploadPic === "" && profile?.isShow && (
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <span
              style={{
                display: "inline-block",
                width: "50px",
                height: "50px",
                margin: "0 0.5rem",
              }}
            >
              <img
                className="Image"
                style={{ width: "100%", height: "100%" }}
                src={`${process.env.REACT_APP_URI}/${profile?.isShow}`}
              />
            </span>
            <span
              className="cross"
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                setProfile((prev) => ({
                  ...prev,
                  uploadPic: "",
                }));
              }}
            >
              <Cancel
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white?.main : dark.main,
                })}
              />
            </span>
          </div>
        )}
      </MDBox>
      <MDBox
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        lineHeight={1}
        gap={3}
        width={"75%"}
      >
        <MDTypography variant="h6">
          Category Banner Image{" "}
          <MDTypography variant="body1" component="span" fontSize={11}>
            &nbsp; ( image size - 178 × 178 px )
          </MDTypography>
        </MDTypography>
        <ImagePicker
          disabled={createUpdateLoading}
          // required={!profile?.bannerVideo}
          name="bannerImage"
          multiple={true}
          images={profile?.bannerImage}
          setImages={(e) => {
            setProfile((prev) => ({
              ...prev,
              bannerImage: e,
            }));
          }}
          //
        />
        {profile?.serverBannerImage && profile?.serverBannerImage?.length ? (
          <div style={{ display: "flex", alignItems: "flex-start", gap: "0.5rem" }}>
            {" "}
            {profile?.serverBannerImage?.map((ele, i) => (
              <span style={{ display: "flex", alignItems: "flex-start" }} key={i}>
                <span
                  style={{
                    display: "inline-block",
                    width: "50px",
                    height: "50px",
                    margin: "0 0.5rem",
                  }}
                >
                  <img
                    className="Image"
                    style={{ width: "100%", height: "100%" }}
                    src={`${process.env.REACT_APP_URI}/${ele?.url}`}
                  />
                </span>
                <span
                  className="cross"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    dispatch(
                      unlinkImage({
                        url: `/unLinks/${profile?.categoryId}/${admin}`,
                        data: { imageIndex: i + 1 },
                        notFormData: true,
                      })
                    ).then((data) => {
                      if (data?.payload?.success) {
                        const temp = [...profile?.serverBannerImage];
                        temp.splice(i, 1);
                        setProfile((prev) => ({
                          ...prev,
                          serverBannerImage: temp,
                        }));
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: `${data.payload.success ? "success" : "error"}`,
                            msg: data.payload.message,
                          })
                        );
                      }
                    });
                  }}
                >
                  <Cancel
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white?.main : dark.main,
                    })}
                  />
                </span>
              </span>
            ))}
          </div>
        ) : null}
      </MDBox>

      <MDBox
        sx={{ width: "100%", justifyContent: "flex-end", textAlign: "center", display: "flex" }}
      >
        <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
          {createUpdateLoading ? (
            <CircularProgress color="primary" size={20} />
          ) : isPUpdates ? (
            " Update Category "
          ) : (
            "Create Category"
          )}
        </MDButton>
      </MDBox>
    </MDBox>




    </MDBox>



  
  );
};

export default PcatogeroyUpdate;

PcatogeroyUpdate.propTypes = {
  viewData: PropTypes.any,
  filter: PropTypes.bool,
  isPUpdates: PropTypes.bool,
  setIsOpenUpdate: PropTypes.func,
};
