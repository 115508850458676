import { BorderColor, Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import http from "Utils/api";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SkAccordions from "./Option/Accordian";
import { getAllQna } from "redux/festures/supportandQnaSlice";
import Form from "./Option/Form";
import { getOneQna } from "redux/festures/supportandQnaSlice";

const columns = {
  AllTax: [
    { Header: "S.No", accessor: "no" },
    { Header: "Question", accessor: "question" },
    { Header: "Answer", accessor: "answer" },
    { Header: "action", accessor: "action" },
  ],
};
const QNA = () => {
  const [controller] = useMaterialUIController();
  const dispatch = useDispatch();
  const { darkMode } = controller;
  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, QNAData } = useSelector((state) => state.isSupport);
  // console.log(QNAData, "QNAData");
  useEffect(() => {
    dispatch(getAllQna({ url: "getAllQuestion" }));
  }, []);
  useEffect(() => {
    if (QNAData && QNAData.length > 0) {
      const temprows =
        QNAData &&
        QNAData?.at(0) &&
        QNAData?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          question: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                {/* {console.log(value?.taxPercent)} */}
                {value?.question || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          answer: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>
                {/* {console.log(value?.taxPercent)} */}
                {value?.answer || "N/A"}
              </MDTypography>
            </MDBox>
          ),
          action: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  // setIsOpenUpdate(true);
                  // console.log(value?._id);
                  dispatch(getOneQna({ url: `getByIdQuestion/${value?._id}` }));
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [QNAData]);
  return (
    <>
      {" "}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Question and Answer{" "}
              </MDTypography>
            </MDBox>
            <MDBox py={3}>
              {isLoading ? (
                <SkLoading />
              ) : QNAData && QNAData.length > 0 ? (
                <DataTable
                  table={{
                    columns: columns?.AllTax,
                    rows: rowsData,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  isPages={QNAData && QNAData.length}
                  noEndBorder
                  canSearch={false}
                  showTotalEntries={false}
                  pagination={false}
                  isPagination={false}
                />
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h5">QNA Not Found ...!</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
      >
        <Form setIsOpen={setIsOpen} />
      </SkModal>
    </>
  );
};

export default QNA;
