import { Close, Delete, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { deleteBrands } from "redux/festures/brandSlice";
import { updateBrands } from "redux/festures/brandSlice";
import { getBrands } from "redux/festures/brandSlice";
import { formattedDateServer } from "Utils/dateFunc";
import CreateBranner from "./createBanner";
import SkConfirm from "components/SkComfirm";
import { getAllHomeBanner, getSingleHomeBanner } from "redux/festures/homeBannerSlice";
import { getPartnerBanner } from "redux/festures/homeBannerSlice";
const columns = {
  AllBrands: [
    // { Header: "S.No", accessor: "no" },
    // { Header: "brand details", accessor: "brand details" },
    // // { Header: "Brand status", accessor: "Brand status" },
    // // { Header: "payment method ", accessor: "payment method" },
    // // { Header: "total Brands", accessor: "total Brands" },
    // // { Header: "place Brand", accessor: "place Brand" },
    // { Header: "date", accessor: "date" },
    // // { Header: "showInHome", accessor: "showInHome" },
    // { Header: "disable", accessor: "disable" },
    // { Header: "view", accessor: "view" },
    // { Header: "action", accessor: "action" },
    // { Header: "delete", accessor: "delete" },
  ],
};
const PartnerBanner = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const { AllHomeBanner, PartnerBannerData } = useSelector((state) => ({
    ...state.isHomeBanner,
  }));
  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState({ open: false, isId: null });
  console.log(PartnerBannerData, "PartnerBannerData");

  useEffect(() => {
    dispatch(getPartnerBanner(`getBypartnerProfilehome`));
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Partner Banner &apos;s{" "}
            </MDTypography>
            <MDButton
              variant="gradient"
              color="dark"
              // disabled={}
              sx={({ palette: { dark, white, info } }) => ({
                color: white.main,
                backgroundColor: dark.main,
                "&:hover": {
                  color: white.main,
                  backgroundColor: dark.main,
                },
              })}
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(true);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp; Update Partner Banner
            </MDButton>
          </MDBox>

          {/* <SkLoading /> */}
        </MDBox>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ height: "80vh", width: "100%", marginBottom: "5%" }}>
            <img
              src={`${process.env.REACT_APP_URI}/${PartnerBannerData?.banner}`}
              alt={"img"}
              onError={(e) => {
                (e.onError = null), (e.target.src = require("../../assets/images/bg-profile.png"));
              }}
              onClick={() => {
                const a = document.createElement("a");
                a.target = "__blank";
                a.href = `${PartnerBannerData?.link}`;
                a.click();
              }}
              style={{ width: "100%", height: "100%", cursor: "pointer" }}
            />
          </Box>
        </Box>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <CreateBranner
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        {/* <SingleProduct viewProductId={viewProductId} setViewProductModal={setViewProductModal} /> */}
      </SkModal>
    </>
  );
};

export default PartnerBanner;
