import { Card, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";

const SingleCategory = ({ viewData }) => {
    // console.log(viewData,"viewData")
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            Category Details
          </MDTypography>
        </Card>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 2,
            width: "100%",
            //   height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
          })}
        >
          <MDBox
            sx={{
              height: 200,
              width: 200,
              borderRadius: "50%",
              border: 3,
              borderColor: "primary.main",
            }}
          >
            <img
              src={`${process.env.REACT_APP_URI}/${viewData?.icon}`}
              style={{ height: "100%", width: "100%", borderRadius: "50%", objectFit: "cover" }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
              }}
            />
          </MDBox>
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              // border: 0.5,
              // borderColor: darkMode ? white.main : dark.main,
              // borderRadius: 3,

              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 1.5,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Category Name :</MDTypography>
              <MDTypography
                variant="h6"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {viewData?.name || "-"}{" "}
              </MDTypography>
            </MDBox>
            {/* <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6">Cities :</MDTypography>
              {viewData?.cityId && viewData?.cityId?.length ? (
                <MDTypography
                  variant="h6"
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "wrap",
                    textOverflow: "ellipsis",
                    maxWidth: "70%",
                  }}
                >
                  {viewData?.cityId?.map((e) => e.cityName)?.join(",") || "-"}
                </MDTypography>
              ) : (
                "-"
              )}
            </MDBox> */}
            <Divider/>
          </MDBox>
          {viewData?.banner && viewData?.banner?.length
            ? viewData?.banner?.map((ele, i) => (
                <MDBox
                  key={i}
                  sx={{
                    height: "40vh",
                    width: "100%",
                  }}
                >
                  {ele?.type === "IMAGE" ? (
                    <>
                      <MDTypography variant="h6">Category Banner :</MDTypography>
                      <img
                        src={`${process.env.REACT_APP_URI}/${ele?.url}`}
                        style={{ height: "90%", width: "100%", objectFit: "cover" }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <MDTypography variant="h6">Category Video</MDTypography>
                      <video width="320" height="240" controls>
                        <source src={`${process.env.REACT_APP_URI}/${ele?.url}`} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </>
                  )}
                </MDBox>
              ))
            : null}
        </MDBox>
      </MDBox>
    </>
  );
};

export default SingleCategory;

SingleCategory.propTypes = {
  viewData: PropTypes.any,
};
