import * as XLSX from "xlsx";
import http from "Utils/api2";
import { formattedDateServer } from "Utils/dateFunc";
import { SkPrice } from "Utils/dateFunc";

// Function to fetch data from a specific page
const fetchData = async (url, page) => {
  try {
    const res = await http.get(`${url}&page=${page}`);
    return res.data?.data;
  } catch (error) {
    return { data: [] };
  }
  // try {
  //   const response = await fetch(`${url}&page=${page}`);
  //   const result = await response.json();
  //   return result.data; // Assuming the data is stored in result.data
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   return [];
  // }
};

export const generateUserExcelData = async ({ url, totalPages, loading }) => {
  // Get the total number of pages
  // const totalPages = await getTotalPages();

  // Accumulate data from all pages
  loading(true);
  try {
    // Array of promises for each page fetch
    const pagePromises = [];

    for (let page = 1; page <= totalPages; page++) {
      pagePromises.push(fetchData(url, page));
    }

    // Fetch all pages concurrently using Promise.all
    const isData = await Promise.all(pagePromises);

    // Combine the results from each page
    const combinedData = isData.flat(); // Or use .concat() if older versions of JS

    // console.log(combinedData, "ASdfs");
    // allData = combinedData;

    // Now generate the PDF with the accumulated data
    exportToExcel(combinedData);
  } catch (error) {
    console.log(error, "pdf");
  } finally {
    loading(false);
  }
};

const exportToExcel = (allListData) => {
  const data = allListData?.map((ele, i) => ({
    "S.No": i + 1,
    Name: `${ele?.fullName}`,
    CustomerId: `${ele?._id}`,
    Email: `${ele?.email}`,
    "Phone No.": `${ele?.phoneNumber}`,
    "Registration Date": ele?.createdAt
      ? `${formattedDateServer(new Date(ele?.createdAt) || "-") || "-"}`
      : "-",
  }));
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Customer");

  // Set column widths
  worksheet["!cols"] = [
    { wpx: 50 }, // S.No column
    { wpx: 200 }, // User Name column
    { wpx: 200 }, // User Name column
    { wpx: 200 }, // Order Id column
    { wpx: 150 }, // Order Status column
    { wpx: 200 }, // Order Id column
  ];

  // Get the range of the worksheet to ensure all cells exist
  const range = XLSX.utils.decode_range(worksheet["!ref"]);

  // Apply styles to header cells if they exist
  if (range.s.r === 0) {
    const headerStyle = {
      fill: { fgColor: { rgb: "FFFFA500" } }, // Orange background color
      font: { color: { rgb: "FFFFFFFF" }, bold: true, sz: 12 }, // White font color, bold, and size 12
    };
    worksheet["A1"].s = headerStyle; // S.No header
    worksheet["B1"].s = headerStyle; // User Name header
    worksheet["C1"].s = headerStyle; // Order Id header
    worksheet["D1"].s = headerStyle; // Order Status header
    worksheet["F1"].s = headerStyle; // Order Status header
  }

  // Save to file
  XLSX.writeFile(workbook, "Customer List.xlsx");
};
