import { MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import React from "react";
import PropTypes from "prop-types";


const disableFilter = [
    {
      _id: 0,
      name: "All",
    },
    {
      _id: false,
      name: "Active",
    },
    {
      _id: true,
      name: "Delete",
    },
  ];

const MenuSelecter = ({ isDisable, setIsDisable, disable }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;


  return (
    <div>
      <MDBox py={3} display="flex" justifyContent="end">
        <MDBox width="100%" display="flex" flexDirection="column" padding="2%">
          <MDTypography variant="button">Filter By Visibility</MDTypography>
          <Select
            disabled={disable}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={isDisable}
            onChange={(e) => setIsDisable(e.target.value)}
            sx={({ palette: { dark, white, info } }) => ({
              width: "100%",
              height: "3rem",
              color: darkMode ? white?.main : dark?.main,
              bgcolor: "transparent",
              "&	.MuiSelect-icon": {
                color: darkMode ? white?.main : dark?.main,
                display: "block !important",
                fontSize: "1.5rem !important",
              },
            })}
          >
            {disableFilter?.map((ele, i) => (
              <MenuItem component="option" key={i} value={ele?._id}>
                {ele?.name}
              </MenuItem>
            ))}
          </Select>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default MenuSelecter;

MenuSelecter.propTypes = {
  isDisable: PropTypes.any,
  setIsDisable: PropTypes.fun,
  disable: PropTypes.any,
};
